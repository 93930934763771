export enum CategoryType {
  Accessories = 'CLOTHES_SHOES_ACCESSORIES',
  Kids = 'KIDS',
  Car = 'CAR',
  FoodDrink = 'FOOD_DRINKS',
  HealthBeauty = 'HEALTH_BEAUTY',
  HomeGarden = 'HOME_GARDEN',
  Office = 'OFFICE',
  Others = 'OTHERS',
  Pet = 'PET',
  Sport = 'SPORT',
  TechnicalProducts = 'TECHNICAL_PRODUCTS',
  Tobacco = 'TOBACCO',
  Toys = 'TOYS',
  Unknown = 'UNKNOWN',
}
