
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Parts/Icon.vue';
import Spinner from '@/components/Parts/Spinner.vue';
import Filter from '@/components/Filtering/FilterItem.vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import { FilterItem, FilterSelections } from '@/components/data/interfaces/selector.interface';
import { FilterType } from '@/components/data/enums/selector.enum';
import { commonMethods } from '@/components/Mixins/common.mixin';
import modalSelector from '@/components/Mixins/modalSelector.mixin';
import { coreApp } from '@/core/app';

const FilterSelector = defineComponent({
  components: {
    Icon,
    Filter,
    Spinner,
  },
  mixins: [commonMethods],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'trigger-filter'],
  setup: (props) => {
    const { closeModal, isVisible, showModal } = modalSelector(props);
    const selectorItems = ref(null as FilterSelections | null);
    const getFilterSelections = async () => {
      selectorItems.value = await coreApp.productService.getFilteringItems();
    };

    const clearFilterCategories = () => {
      selectorItems.value?.categories.forEach((item) => {
        item.selected = item.id === '-1';
      });
    };
    const clearFilterCompanies = () => {
      selectorItems.value?.companies.forEach((item) => {
        item.selected = item.id === '-1';
      });
    };

    const selectCategoryFilter = (id: string) => {
      selectorItems.value?.categories.find((item) => {
        if (item.id === '-1') {
          item.selected = false;
        } else if (item.id === id) {
          item.selected = true;
        }
      });
    };
    const selectCompanyFilter = (id: string) => {
      selectorItems.value?.companies.find((item) => {
        if (item.id === '-1') {
          item.selected = false;
        } else if (item.id === id) {
          item.selected = true;
        }
      });
    };

    const unselectCategoryFilter = (id: string) => {
      selectorItems.value?.categories.find((item) => {
        if (item.id === id) {
          item.selected = false;
        }
      });
      if (!selectorItems.value?.categories.find((item) => item.selected)) {
        clearFilterCategories();
      }
    };
    const unselectCompanyFilter = (id: string) => {
      selectorItems.value?.companies.find((item) => {
        if (item.id === id) {
          item.selected = false;
        }
      });
      if (!selectorItems.value?.companies.find((item) => item.selected)) {
        clearFilterCompanies();
      }
    };

    return {
      closeModal,
      isVisible,
      showModal,
      selectorItems,
      getFilterSelections,
      clearFilterCategories,
      clearFilterCompanies,
      selectCategoryFilter,
      selectCompanyFilter,
      unselectCategoryFilter,
      unselectCompanyFilter,
    };
  },
  data: () => {
    return {
      IconSizeType,
      FilterType,
    };
  },
  methods: {
    clearAll() {
      this.clearFilterCategories();
      this.clearFilterCompanies();
    },
    selectFilter(item: { filter: FilterItem; type: FilterType }): void {
      if (item.filter.selected) {
        if (item?.type === FilterType.Category) {
          this.unselectCategoryFilter(item.filter.id);
        } else {
          this.unselectCompanyFilter(item.filter.id);
        }
      } else {
        if (item.filter.id === '-1') {
          if (item?.type === FilterType.Category) {
            this.clearFilterCategories();
          } else {
            this.clearFilterCompanies();
          }
        } else {
          if (item?.type === FilterType.Category) {
            this.selectCategoryFilter(item.filter.id);
          } else {
            this.selectCompanyFilter(item.filter.id);
          }
        }
      }
    },
    saveFilters() {
      if (coreApp.productService.saveFilterItems(this.selectorItems)) {
        this.$emit('trigger-filter');
      }
    },
  },
  watch: {
    async show(value) {
      if (value) {
        await this.getFilterSelections();
      }
    },
  },
});
export default FilterSelector;
