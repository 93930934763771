<template>
  <div class="content-area">
    <ToastError v-if="error && error.code !== 499" @close-error="error = null" />
    <Navigation :count="favoritesCount" @navigate="toTab" />
    <div v-if="activeTab" class="tab-content">
      <List
        :key="activeTab"
        :tab="activeTab"
        :infinityLoader="infinityLoader"
        :products="products"
        :sortOptions="sortOptions"
        :closeModal="closeModal"
        @select-selector="selectSort"
        @remove-product="removeProduct"
        @load-next-page="triggerLoadNextPage"
        @hide-keyboard="hideKeyboard"
        @count-favorite="countFavorite"
      />
    </div>
    <Footer
      v-if="showFooter"
      :closeModal="closeModal"
      :hiddenKeyboard="hiddenKeyboard"
      @trigger-search="triggerSearch"
      @trigger-filter="triggerFilter"
    />
  </div>
</template>

<script lang="ts">
import Navigation from '@/components/Navigation/Navigation.vue';
import List from '@/components/Product/Products.vue';
import Footer from '@/components/Footer/Footer.vue';
import ToastError from '@/components/Parts/ToastError.vue';
import { QueryTab } from '@/components/data/enums/navigation.enum';
import { defineComponent, onMounted, ref } from 'vue';
import { InfinityLoader } from '@/components/data/interfaces/products.interface';
import { DtoProductResponse, Product } from '@/core/data/interfaces/product.interface';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { coreApp } from '@/core/app';
import { SortingTypeBy } from '@/components/data/enums/selector.enum';
import { ApiError } from '@/core/network/http/httpClient.interface';

const Home = defineComponent({
  components: { Navigation, List, Footer, ToastError },
  props: {
    closeModal: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['reduce-padding'],
  data: () => {
    return {
      showFooter: true,
      hiddenKeyboard: false,
      // eslint-disable-next-line no-undef
      timeout: {} as NodeJS.Timeout,
      // eslint-disable-next-line no-undef
      keyboardTimeout: {} as NodeJS.Timeout,
    };
  },
  setup: () => {
    const infinityLoader = ref({
      nextPage: false,
      loading: false,
    } as InfinityLoader);
    const error = ref(null as ApiError | null);
    const searchQuery = ref('');
    let queryParams = coreApp.productService.getQueryParams();
    const activeTab = ref(queryParams?.tab || QueryTab.List);
    const searching = ref(false);
    const favoritesCount = ref(0);

    const products = ref(null as Product[] | null);
    const productResponse = ref(null as DtoProductResponse | null);
    const getProducts = async (sort: SortItem): Promise<Product[] | null> => {
      productResponse.value = null;
      infinityLoader.value.loading = true;
      infinityLoader.value.nextPage = false;
      const result = await coreApp.productService.getAllProducts(
        sort,
        activeTab.value,
        searchQuery.value,
      );
      if (result.data) {
        productResponse.value = result.data;
        infinityLoader.value.loading = false;
        return productResponse.value.results || [];
      } else {
        error.value = result.error || null;
        infinityLoader.value.loading = result.error?.code === 499;
        return null;
      }
    };

    const sortOptions = ref([] as SortItem[]);
    const getSortOptions = () => {
      sortOptions.value = [
        {
          name: 'sorting_option_recommended',
          type: SortingTypeBy.Recommended,
          isActive: true,
        },
        {
          name: 'sorting_option_valid_from',
          type: SortingTypeBy.ValidFrom,
          isActive: false,
        },
        {
          name: 'sorting_option_valid_to',
          type: SortingTypeBy.ValidTo,
          isActive: false,
        },
        {
          name: 'sorting_option_price_high_to_low',
          type: SortingTypeBy.PriceHighToLow,
          isActive: false,
        },
        {
          name: 'sorting_option_price_low_to_high',
          type: SortingTypeBy.PriceLowToHigh,
          isActive: false,
        },
      ];
    };

    const getFavoritesCount = async (): Promise<void> => {
      favoritesCount.value = await coreApp.productService.getNumberOfFavorites();
    };

    onMounted(getSortOptions);

    const loadNextPage = async () => {
      if (!infinityLoader.value.loading && productResponse.value?.next) {
        infinityLoader.value.nextPage = true;
        const result = await coreApp.productService.getNextPage(productResponse.value.next);
        productResponse.value = null;
        if (result.data && infinityLoader.value.nextPage) {
          productResponse.value = result.data;
          if (productResponse.value.results?.length > 0) {
            products.value?.push(...productResponse.value.results);
          }
        }
      }
      infinityLoader.value.nextPage = false;
    };

    const toTabSet = () => {
      queryParams = coreApp.productService.getQueryParams();
      activeTab.value = queryParams?.tab || QueryTab.List;
    };

    return {
      infinityLoader,
      products,
      getProducts,
      getFavoritesCount,
      sortOptions,
      getSortOptions,
      loadNextPage,
      error,
      toTabSet,
      activeTab,
      searchQuery,
      searching,
      favoritesCount,
    };
  },
  async mounted() {
    const hideFooter = this.activeTab === QueryTab.Favorites;
    await this.getFavoritesCount();
    await this.fetchItems(hideFooter);
  },
  methods: {
    async fetchItems(hideFooter: boolean) {
      this.products = await this.getProducts(this.getActiveSort);
      if (this.products && this.products.length > 0) {
        this.shownFooter();
      } else if (!this.searching && hideFooter) {
        this.hideFooter();
      }
    },
    async triggerLoadNextPage() {
      if (!this.infinityLoader.nextPage) {
        await this.loadNextPage();
      }
    },
    async triggerFilter() {
      this.products = null;
      setTimeout(async () => {
        await this.fetchItems(false);
      }, 50);
    },
    async triggerSearch(query: string) {
      this.searchQuery = query;
      clearTimeout(this.keyboardTimeout);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(async () => {
        this.hideKeyboard();
        this.searching = true;
        await this.fetchItems(false);
        this.searching = false;
      }, 50);
    },
    removeProduct(product: Product): void {
      if (this.products) {
        const index = this.products.indexOf(product);
        this.products.splice(index, 1);
      }
    },
    selectSort(sortItem: SortItem) {
      this.products = null;
      setTimeout(async () => {
        this.sortOptions.forEach((item) => {
          item.isActive = sortItem.type === item.type;
        });
        await this.fetchItems(false);
      }, 150);
    },
    async toTab(): Promise<void> {
      this.toTabSet();
      const hideFooter = this.activeTab === QueryTab.Favorites;
      await this.fetchItems(hideFooter);
    },
    hideFooter() {
      this.showFooter = false;
      this.$emit('reduce-padding', true);
    },
    shownFooter() {
      this.$emit('reduce-padding', false);
      this.showFooter = true;
    },
    hideKeyboard() {
      clearTimeout(this.keyboardTimeout);
      if (!this.searching) {
        this.hiddenKeyboard = true;

        this.keyboardTimeout = setTimeout(() => {
          this.hiddenKeyboard = false;
        }, 150);
      }
    },
    countFavorite(isFavorite: boolean): void {
      if (isFavorite) {
        this.favoritesCount++;
      } else {
        this.favoritesCount--;
      }
    },
  },
  computed: {
    getActiveSort(): SortItem {
      return this.sortOptions.find((sort) => sort.isActive) || this.sortOptions[0];
    },
  },
});
export default Home;
</script>

<style lang="scss" scoped>
.tab-content {
  padding: 0 8px 32px;

  & div {
    border-top: 1px solid var(--background-color-seperator);
  }
}
</style>
