
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import Icon from '@/components/Parts/Icon.vue';
import Sort from '@/components/Sorting/SortItem.vue';
import { commonMethods } from '@/components/Mixins/common.mixin';
import modalSelector from '@/components/Mixins/modalSelector.mixin';

const SortSelector = defineComponent({
  components: {
    Icon,
    Sort,
  },
  mixins: [commonMethods],
  props: {
    selectorItems: {
      type: Object as PropType<SortItem[]>,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'select-sort'],
  setup: (props) => {
    const { closeModal, isVisible, showModal } = modalSelector(props);

    return {
      closeModal,
      isVisible,
      showModal,
    };
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
  methods: {
    selectSort(sort: SortItem): void {
      this.$emit('select-sort', sort);
    },
  },
});
export default SortSelector;
