<template>
  <div v-if="sort" class="sort-item">
    <div v-if="sort.isActive" class="sort-item-icon-active">
      <Icon name="check" :size="IconSizeType.XSmall" />
    </div>
    <div class="sort-item-name" :class="{ active: sort.isActive }">
      {{ translate(sort.name) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Sort = defineComponent({
  components: {
    Icon,
  },
  mixins: [commonMethods],
  props: {
    sort: {
      type: Object as PropType<SortItem>,
      required: true,
    },
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
});
export default Sort;
</script>

<style lang="scss" scoped>
.sort-item {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: center;

  &-name {
    position: relative;
    left: 32px;
    font-size: 15px;
    letter-spacing: 0.27px;

    &.active {
      color: var(--font-color-primary);
    }
  }

  &-icon-active {
    position: absolute;
  }
}
</style>
