export enum BasicIconType {
  ArrowBack = 'arrow_back',
  ArrowDown = 'arrow_down',
  Check = 'check',
  Clock = 'clock',
  Close = 'close',
  Filter = 'filter',
  HeartFull = 'heart_full',
  HeartEmpty = 'heart_empty',
  Search = 'search',
  Sort = 'sort',
}

export enum IconSizeType {
  XSmall = '14px',
  Small = '16px',
  Large = '20px',
  XLarge = '24px',
}
