export default {
  mounted: (htmlElement: HTMLElement): void => {
    const loadImage = () => {
      const imageElement = Array.from(htmlElement.children).find(
        (el: Element) => el.nodeName === 'IMG',
      ) as HTMLImageElement;
      let loaded = false;

      if (imageElement) {
        imageElement.addEventListener('load', () => {
          loaded = true;
          if (imageElement.naturalWidth > imageElement.naturalHeight) {
            imageElement.classList.add('landscape');
          } else {
            imageElement.classList.add('portrait');
          }
          setTimeout(() => {
            htmlElement.classList.remove('hide');
          }, 100);
        });
        setTimeout(() => {
          if (!loaded) {
            htmlElement.classList.add('hide');
          }
        }, 5);
        imageElement.addEventListener('error', () => {});
        imageElement.src = imageElement.dataset.url || '';
      }
    };

    const handleIntersect = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver,
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(htmlElement);
        }
      });
    };

    const createObserver = () => {
      const options: IntersectionObserverInit = {
        // circumstances under which the observer's callback is invoked
        root: null, // defaults to the browser viewport if not specified or if null
        threshold: 0, // the degree of intersection between the target element and its root (0 - 1)
        // threshold of 1.0 means that when 100% of the target is visible within
        // the element specified by the root option, the callback is invoked
      };

      // Whether you're using the viewport or some other element as the root,the API works the same way,
      // executing a callback function you provide whenever the visibility of the target element changes
      // so that it crosses desired amounts of intersection with the root

      const observer = new IntersectionObserver(handleIntersect, options);

      observer.observe(htmlElement); // target element to watch
    };

    if (!window.IntersectionObserver) {
      loadImage();
    } else {
      createObserver();
    }
  },
};
