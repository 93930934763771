<template>
  <div v-if="isVisible">
    <div class="modal-selector" :class="{ show: showModal }">
      <div class="modal-selector-space">
        <div class="selector-header filtering">
          <div class="clear-filters" @click="clearAll">
            {{ translate('filtering_header_clear') }}
          </div>
          <div class="selector-header-title">
            {{ translate('filtering_header_title') }}
          </div>
          <div class="selector-header-close" @click="closeModal">
            <Icon name="close" />
          </div>
        </div>
        <div class="selector-items">
          <div v-if="selectorItems" class="selector-items-group">
            <div class="selector-items-title">
              {{ translate('filtering_section_categories_title') }}
            </div>
            <div class="selector-items-contents">
              <Filter
                v-for="(item, i) in selectorItems.categories"
                :key="`cat-item-${i}`"
                :filter="item"
                :type="FilterType.Category"
                @select-filter="selectFilter"
              />
            </div>
          </div>
          <div v-if="selectorItems" class="selector-items-group">
            <div class="selector-items-title">
              {{ translate('filtering_section_company_title') }}
            </div>
            <div class="selector-items-contents">
              <Filter
                v-for="(item, i) in selectorItems.companies"
                :key="`comp-item-${i}`"
                :filter="item"
                :type="FilterType.Company"
                @select-filter="selectFilter"
              />
            </div>
          </div>
          <div v-if="!selectorItems" class="loader">
            <Spinner />
          </div>
        </div>
        <div class="selector-footer">
          <div class="button" @click="saveFilters">
            {{ translate('filtering_button_text') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import Icon from '@/components/Parts/Icon.vue';
import Spinner from '@/components/Parts/Spinner.vue';
import Filter from '@/components/Filtering/FilterItem.vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import { FilterItem, FilterSelections } from '@/components/data/interfaces/selector.interface';
import { FilterType } from '@/components/data/enums/selector.enum';
import { commonMethods } from '@/components/Mixins/common.mixin';
import modalSelector from '@/components/Mixins/modalSelector.mixin';
import { coreApp } from '@/core/app';

const FilterSelector = defineComponent({
  components: {
    Icon,
    Filter,
    Spinner,
  },
  mixins: [commonMethods],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'trigger-filter'],
  setup: (props) => {
    const { closeModal, isVisible, showModal } = modalSelector(props);
    const selectorItems = ref(null as FilterSelections | null);
    const getFilterSelections = async () => {
      selectorItems.value = await coreApp.productService.getFilteringItems();
    };

    const clearFilterCategories = () => {
      selectorItems.value?.categories.forEach((item) => {
        item.selected = item.id === '-1';
      });
    };
    const clearFilterCompanies = () => {
      selectorItems.value?.companies.forEach((item) => {
        item.selected = item.id === '-1';
      });
    };

    const selectCategoryFilter = (id: string) => {
      selectorItems.value?.categories.find((item) => {
        if (item.id === '-1') {
          item.selected = false;
        } else if (item.id === id) {
          item.selected = true;
        }
      });
    };
    const selectCompanyFilter = (id: string) => {
      selectorItems.value?.companies.find((item) => {
        if (item.id === '-1') {
          item.selected = false;
        } else if (item.id === id) {
          item.selected = true;
        }
      });
    };

    const unselectCategoryFilter = (id: string) => {
      selectorItems.value?.categories.find((item) => {
        if (item.id === id) {
          item.selected = false;
        }
      });
      if (!selectorItems.value?.categories.find((item) => item.selected)) {
        clearFilterCategories();
      }
    };
    const unselectCompanyFilter = (id: string) => {
      selectorItems.value?.companies.find((item) => {
        if (item.id === id) {
          item.selected = false;
        }
      });
      if (!selectorItems.value?.companies.find((item) => item.selected)) {
        clearFilterCompanies();
      }
    };

    return {
      closeModal,
      isVisible,
      showModal,
      selectorItems,
      getFilterSelections,
      clearFilterCategories,
      clearFilterCompanies,
      selectCategoryFilter,
      selectCompanyFilter,
      unselectCategoryFilter,
      unselectCompanyFilter,
    };
  },
  data: () => {
    return {
      IconSizeType,
      FilterType,
    };
  },
  methods: {
    clearAll() {
      this.clearFilterCategories();
      this.clearFilterCompanies();
    },
    selectFilter(item: { filter: FilterItem; type: FilterType }): void {
      if (item.filter.selected) {
        if (item?.type === FilterType.Category) {
          this.unselectCategoryFilter(item.filter.id);
        } else {
          this.unselectCompanyFilter(item.filter.id);
        }
      } else {
        if (item.filter.id === '-1') {
          if (item?.type === FilterType.Category) {
            this.clearFilterCategories();
          } else {
            this.clearFilterCompanies();
          }
        } else {
          if (item?.type === FilterType.Category) {
            this.selectCategoryFilter(item.filter.id);
          } else {
            this.selectCompanyFilter(item.filter.id);
          }
        }
      }
    },
    saveFilters() {
      if (coreApp.productService.saveFilterItems(this.selectorItems)) {
        this.$emit('trigger-filter');
      }
    },
  },
  watch: {
    async show(value) {
      if (value) {
        await this.getFilterSelections();
      }
    },
  },
});
export default FilterSelector;
</script>

<style lang="scss" scoped>
.modal-selector {
  & .selector-header {
    &.filtering {
      grid-template-columns: repeat(3, 1fr);

      & .clear-filters {
        font-size: 16px;
        letter-spacing: 0.2px;
        color: var(--font-color-primary);
      }

      & .selector-header-title {
        justify-self: center;
      }

      & .selector-header-close {
        justify-self: end;
      }
    }
  }

  & .selector-items {
    overflow: auto;
    min-height: 62vh;
    max-height: 62vh;
    margin: 0 0 42px;

    &-group {
      margin: 32px 0;
      padding: 0 8px;

      & .selector-items-title {
        font-size: 15px;
        color: var(--font-color-secondary);
        padding-bottom: 14px;
        border-bottom: 2px solid var(--background-color-secondary);
        margin-bottom: 24px;
      }
    }

    & .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -1.5em;
      margin-left: -1.5em;
      width: 3em;
      height: 3em;
      z-index: 999;
    }
  }

  & .selector-footer {
    width: 100%;
    border-top: 2px solid var(--background-color-secondary);
    padding: 16px 16px 32px;
    margin: 0 -16px;
    position: absolute;
    bottom: 0;

    & .button {
      height: 48px;
      border-radius: 8px;
      background-color: var(--background-color-primary);
      color: #ffffff;
      font-size: 15px;
      letter-spacing: 0.14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
