import axios from 'axios';
import logging from '@/core/logging/logging';
import { Translations } from '@/core/data/interfaces/app.interface';
import { ConfigRepository } from '@/core/data/config/config.repository';
import { HttpResponse } from '@/core/network/http/httpClient.interface';

export class TranslationRepository {
  private readonly config: ConfigRepository;
  private translations?: Translations | null;

  constructor(config: ConfigRepository) {
    this.config = config;
  }

  async getTranslations(language: string): Promise<HttpResponse<Translations>> {
    return axios.get(`/translations/${language}.json`);
  }

  translationsForKey(key: string): string | null {
    return this.translations?.[key] || null;
  }

  async setTranslations(): Promise<void> {
    const language = this.config.getLanguage();
    try {
      const response = await this.getTranslations(language);
      this.translations = response?.data || null;
    } catch (e) {
      logging.error(`Could not found translation file for ${language} language`);
      this.translations = null;
    }
  }
}
