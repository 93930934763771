
import {
  DefineComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  computed,
} from 'vue';
import { coreApp } from '@/core/app';
import { Product } from '@/core/data/interfaces/product.interface';
import ProductItem from '@/components/Product/ProductItem.vue';
import Sorting from '@/components/Sorting/Sorting.vue';
import SortSelector from '@/components/Sorting/SortSelector.vue';
import Spinner from '@/components/Parts/Spinner.vue';
import NoContent from '@/components/Parts/NoContent.vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { InfinityLoader } from '@/components/data/interfaces/products.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Products = defineComponent({
  components: {
    ProductItem,
    Sorting,
    SortSelector,
    Spinner,
    NoContent,
  },
  props: {
    products: {
      required: true,
    },
    sortOptions: {
      type: Object as PropType<SortItem[]>,
      required: true,
    },
    infinityLoader: {
      type: Object as PropType<InfinityLoader>,
      required: true,
    },
    closeModal: {
      type: Boolean,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  mixins: [commonMethods],
  emits: ['select-selector', 'remove-product', 'load-next-page', 'hide-keyboard', 'count-favorite'],
  setup: (props) => {
    const instance = getCurrentInstance();
    const productsComponent = ref(null as DefineComponent | null);
    const { infinityLoader } = toRefs(props);

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
      if (!infinityLoader.value.nextPage) {
        const element = productsComponent.value;
        if (element?.getBoundingClientRect()?.bottom - 200 < window.innerHeight) {
          instance?.emit('load-next-page');
        }
      }
      instance?.emit('hide-keyboard');
    };

    const isLoading = computed((): boolean => {
      return !!(infinityLoader.value.loading && !infinityLoader.value.nextPage);
    });

    return {
      productsComponent,
      isLoading,
    };
  },
  data: () => {
    return {
      isSelectorOpen: false,
    };
  },
  methods: {
    isProductAvailable: (product: Product): boolean => {
      return coreApp.productService.isProductAvailable(product);
    },
    removeProduct(product: Product): void {
      this.$emit('remove-product', product);
    },
    countFavorite(isFavorite: boolean): void {
      this.$emit('count-favorite', isFavorite);
    },
    openSortSelection() {
      this.isSelectorOpen = true;
    },
    selectSort(sortItem: SortItem) {
      this.closeSelector();
      this.$emit('select-selector', sortItem);
    },
    closeSelector(): void {
      this.isSelectorOpen = false;
    },
  },
  computed: {
    getActiveSort(): SortItem {
      return this.sortOptions.find((sort) => sort.isActive) || this.sortOptions[0];
    },
  },
  watch: {
    closeModal(value) {
      if (value) {
        this.closeSelector();
      }
    },
  },
});
export default Products;
