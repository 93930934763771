
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue';
import { NavItem } from '@/components/data/interfaces/navigation.interface';
import { QueryTab } from '@/components/data/enums/navigation.enum';
import TabItem from '@/components/Navigation/TabItem.vue';
import { coreApp } from '@/core/app';

const Navigation = defineComponent({
  components: {
    TabItem,
  },
  props: {
    count: {
      type: Number,
    },
  },
  emits: ['navigate'],
  setup: () => {
    const instance = getCurrentInstance();
    const queryParams = ref(coreApp.productService.getQueryParams());
    const navItems = ref([] as NavItem[]);
    const getNavItems = () => {
      navItems.value = [
        {
          name: 'navigation_tab_all',
          type: QueryTab.List,
          isActive: queryParams.value?.tab === QueryTab.List,
        },
        {
          name: 'navigation_tab_favorites',
          type: QueryTab.Favorites,
          isActive: queryParams.value?.tab === QueryTab.Favorites,
        },
      ];
    };
    onMounted(getNavItems);

    const setActiveTab = (tab: QueryTab) => {
      if (queryParams.value?.tab) {
        queryParams.value.tab = tab;
        coreApp.productService.setQueryParams(queryParams.value);
        instance?.emit('navigate');
      }
    };

    return {
      navItems,
      getNavItems,
      setActiveTab,
    };
  },
  data: () => {
    return {
      selectorHeight: '5px',
    };
  },
  methods: {
    selectTab(selectedItem: NavItem) {
      this.navItems.forEach((item) => {
        item.isActive = selectedItem.type === item.type;
      });
      this.setActiveTab(selectedItem.type);
    },
  },
  computed: {
    tabSelectorHeight(): { '--selector-height': string } {
      return {
        '--selector-height': this.selectorHeight,
      };
    },
    getTabActiveTransform(): { left: string } {
      const index = this.navItems.findIndex((el) => el.isActive);
      return {
        left: `${index * 50}%`,
      };
    },
  },
});
export default Navigation;
