// We need to import the custom SCSS.
require('./theme/css/colors.scss');
require('./theme/css/style.scss');

import { createApp } from 'vue';
import LazyLoadDirective from '@/directives/LazyLoadDirective';
import RippleDirective from '@/directives/Ripple/RippleDirective';
import App from './App.vue';
import router from '@/router';

// const vueApp = createApp(App).use(router).mount('#app');
const vueApp = createApp(App);
vueApp.use(router);
vueApp.directive('lazyload', LazyLoadDirective);
vueApp.directive('ripple', RippleDirective);
vueApp.mount('#app');
