<template>
  <div class="all-products" ref="productsComponent">
    <Sorting
      v-if="tab === 'list' && getActiveSort"
      :sort="getActiveSort"
      @open-sort-selector="openSortSelection"
    />
    <div class="products" :class="{ list: tab === 'list' }">
      <div v-if="isLoading" class="loader">
        <Spinner />
      </div>
      <div v-else-if="products && products.length > 0" class="products-space">
        <div v-for="(product, i) in products" :key="`product.id-${i}`">
          <ProductItem
            v-if="product"
            :product="product"
            :tab="tab"
            @count-favorite="countFavorite"
            @remove-product="removeProduct"
          />
        </div>
      </div>
      <div v-else-if="products && products.length === 0" class="empty-products">
        <NoContent :tab="tab" />
      </div>
    </div>
    <div v-if="infinityLoader.nextPage" class="infinite-loader">
      <Spinner />
    </div>
    <SortSelector
      :show="isSelectorOpen"
      :selectorItems="sortOptions"
      @select-sort="selectSort"
      @close="closeSelector"
    />
  </div>
</template>

<script lang="ts">
import {
  DefineComponent,
  defineComponent,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  toRefs,
  computed,
} from 'vue';
import { coreApp } from '@/core/app';
import { Product } from '@/core/data/interfaces/product.interface';
import ProductItem from '@/components/Product/ProductItem.vue';
import Sorting from '@/components/Sorting/Sorting.vue';
import SortSelector from '@/components/Sorting/SortSelector.vue';
import Spinner from '@/components/Parts/Spinner.vue';
import NoContent from '@/components/Parts/NoContent.vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { InfinityLoader } from '@/components/data/interfaces/products.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Products = defineComponent({
  components: {
    ProductItem,
    Sorting,
    SortSelector,
    Spinner,
    NoContent,
  },
  props: {
    products: {
      required: true,
    },
    sortOptions: {
      type: Object as PropType<SortItem[]>,
      required: true,
    },
    infinityLoader: {
      type: Object as PropType<InfinityLoader>,
      required: true,
    },
    closeModal: {
      type: Boolean,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  mixins: [commonMethods],
  emits: ['select-selector', 'remove-product', 'load-next-page', 'hide-keyboard', 'count-favorite'],
  setup: (props) => {
    const instance = getCurrentInstance();
    const productsComponent = ref(null as DefineComponent | null);
    const { infinityLoader } = toRefs(props);

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    const handleScroll = () => {
      if (!infinityLoader.value.nextPage) {
        const element = productsComponent.value;
        if (element?.getBoundingClientRect()?.bottom - 200 < window.innerHeight) {
          instance?.emit('load-next-page');
        }
      }
      instance?.emit('hide-keyboard');
    };

    const isLoading = computed((): boolean => {
      return !!(infinityLoader.value.loading && !infinityLoader.value.nextPage);
    });

    return {
      productsComponent,
      isLoading,
    };
  },
  data: () => {
    return {
      isSelectorOpen: false,
    };
  },
  methods: {
    isProductAvailable: (product: Product): boolean => {
      return coreApp.productService.isProductAvailable(product);
    },
    removeProduct(product: Product): void {
      this.$emit('remove-product', product);
    },
    countFavorite(isFavorite: boolean): void {
      this.$emit('count-favorite', isFavorite);
    },
    openSortSelection() {
      this.isSelectorOpen = true;
    },
    selectSort(sortItem: SortItem) {
      this.closeSelector();
      this.$emit('select-selector', sortItem);
    },
    closeSelector(): void {
      this.isSelectorOpen = false;
    },
  },
  computed: {
    getActiveSort(): SortItem {
      return this.sortOptions.find((sort) => sort.isActive) || this.sortOptions[0];
    },
  },
  watch: {
    closeModal(value) {
      if (value) {
        this.closeSelector();
      }
    },
  },
});
export default Products;
</script>

<style lang="scss" scoped>
.all-products {
  & .products {
    position: relative;
    display: grid;
    padding-top: 24px;
    align-items: flex-start;
    justify-content: center;
    min-height: 45vh;

    &.list {
      border-top: 1px solid var(--background-color-seperator);
    }

    &-space {
      display: grid;
      grid-template-columns: minmax(0, 1fr) 1fr;
      grid-auto-rows: 1fr;
      grid-gap: 24px 16px;
      min-width: 0;
    }

    & .loader {
      position: absolute;
      left: 50%;
      top: 50%;
      margin-top: -1.5em;
      margin-left: -1.5em;
      width: 3em;
      height: 3em;
      z-index: 999;
    }

    & .empty-products {
      padding: 12px 32px 0;
      text-align: center;

      & .message {
        color: var(--font-color-secondary);
        line-height: 18px;
      }

      & .lost-in-space {
        padding: 32px 24px;
      }
    }
  }

  & .infinite-loader {
    position: relative;
    margin: 25px 0 0;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & .error-loading {
      text-align: center;
      color: var(--font-color-primary);
      margin: 10px 0;
    }
  }
}
</style>
