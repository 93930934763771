<template>
  <div class="navigation">
    <div class="tab-selector" :style="tabSelectorHeight">
      <div class="tab-active" :style="getTabActiveTransform"></div>
    </div>
    <div class="tab-items">
      <div v-for="item in navItems" :key="item.name" @click="selectTab(item)">
        <TabItem :navItem="item" :count="count" @select-tab="selectTab" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, getCurrentInstance, onMounted, ref } from 'vue';
import { NavItem } from '@/components/data/interfaces/navigation.interface';
import { QueryTab } from '@/components/data/enums/navigation.enum';
import TabItem from '@/components/Navigation/TabItem.vue';
import { coreApp } from '@/core/app';

const Navigation = defineComponent({
  components: {
    TabItem,
  },
  props: {
    count: {
      type: Number,
    },
  },
  emits: ['navigate'],
  setup: () => {
    const instance = getCurrentInstance();
    const queryParams = ref(coreApp.productService.getQueryParams());
    const navItems = ref([] as NavItem[]);
    const getNavItems = () => {
      navItems.value = [
        {
          name: 'navigation_tab_all',
          type: QueryTab.List,
          isActive: queryParams.value?.tab === QueryTab.List,
        },
        {
          name: 'navigation_tab_favorites',
          type: QueryTab.Favorites,
          isActive: queryParams.value?.tab === QueryTab.Favorites,
        },
      ];
    };
    onMounted(getNavItems);

    const setActiveTab = (tab: QueryTab) => {
      if (queryParams.value?.tab) {
        queryParams.value.tab = tab;
        coreApp.productService.setQueryParams(queryParams.value);
        instance?.emit('navigate');
      }
    };

    return {
      navItems,
      getNavItems,
      setActiveTab,
    };
  },
  data: () => {
    return {
      selectorHeight: '5px',
    };
  },
  methods: {
    selectTab(selectedItem: NavItem) {
      this.navItems.forEach((item) => {
        item.isActive = selectedItem.type === item.type;
      });
      this.setActiveTab(selectedItem.type);
    },
  },
  computed: {
    tabSelectorHeight(): { '--selector-height': string } {
      return {
        '--selector-height': this.selectorHeight,
      };
    },
    getTabActiveTransform(): { left: string } {
      const index = this.navItems.findIndex((el) => el.isActive);
      return {
        left: `${index * 50}%`,
      };
    },
  },
});
export default Navigation;
</script>

<style lang="scss" scoped>
.navigation {
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);

  & .tab-selector {
    width: 100%;
    position: absolute;
    background-color: var(--background-color-seperator);
    height: var(--selector-height);
    display: flex;
    overflow: hidden;
    z-index: 10;

    & .tab-active {
      display: flex;
      width: 50%;
      height: inherit;
      position: absolute;
      border-radius: var(--selector-height);
      background-color: var(--background-color-primary);
      transition: left var(--duration) var(--cubic-bezier) 0s;
    }
  }

  & .tab-items {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    z-index: 1;
  }
}
</style>
