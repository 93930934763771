<template>
  <img @click="$emit('click', $event)" :src="src" class="icon" :alt="name" :style="fontSize" />
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      src: require(`@/theme/icons/${this.name}.svg`),
    };
  },
  computed: {
    fontSize() {
      return '--size: ' + (this.size ? this.size : '18px');
    },
  },
};
</script>

<style lang="scss">
.icon {
  display: inline-flex;
  vertical-align: middle;
  width: var(--size);
  max-height: 100%;
}
</style>
