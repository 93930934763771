<template>
  <div class="sorting" v-ripple @click="$emit('open-sort-selector')">
    <div class="sort-space">
      <div class="sort-icon">
        <Icon name="sort" :size="IconSizeType.XLarge" />
      </div>
      <div v-if="sort" class="sort-name">{{ translate(sort.name) }}</div>
      <div class="sort-change">
        <Icon name="arrow_down" :size="IconSizeType.XLarge" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Sorting = defineComponent({
  components: {
    Icon,
  },
  emits: ['open-sort-selector'],
  mixins: [commonMethods],
  props: {
    sort: {
      type: Object as PropType<SortItem>,
      required: true,
    },
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
  methods: {},
});
export default Sorting;
</script>

<style lang="scss" scoped>
.sorting {
  position: relative;
  margin: 0 -8px;

  & .sort-space {
    padding: 16px;
    display: grid;
    grid-template-columns: max-content auto max-content;
    grid-auto-rows: 1fr;
    align-items: center;

    & .sort-name {
      margin-left: 16px;
    }
  }
}
</style>
