export enum SortingTypeBy {
  Recommended = 'id',
  ValidFrom = 'created',
  ValidTo = 'price__discount__valid_to',
  PriceLowToHigh = 'discount_price',
  PriceHighToLow = '-discount_price',
}

export enum FilterType {
  Category = 'category',
  Company = 'company',
}
