<template>
  <div v-if="navItem" class="tab-item" v-ripple>
    <div aria-label="name" class="name" :class="{ active: navItem.isActive }">
      {{ translate(navItem.name) }}
      <div v-if="isCounting" class="badge">
        <span>{{ count }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { NavItem } from '@/components/data/interfaces/navigation.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const TabItem = defineComponent({
  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  mixins: [commonMethods],
  computed: {
    isCounting(): boolean {
      if (this.navItem.type === QueryTab.Favorites) {
        return this.count > 0;
      }
      return false;
    },
  },
});
export default TabItem;
</script>

<style lang="scss" scoped>
.tab-item {
  width: 100%;
  height: 57px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  & .name {
    color: var(--font-color-secondary);
    display: flex;
    position: relative;
    font-size: 15px;
    font-weight: 500;
    z-index: 10;
    top: 4px;

    &.active {
      color: var(--font-color-primary);
    }

    & .badge {
      margin-left: 8px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      font-size: 10px;
      border-radius: 50%;
      font-weight: 700;
      background-color: var(--background-color-badge);
      color: var(--font-color-primary) !important;
    }
  }
}
</style>
