import { ApiError, HttpErrorInterface } from './httpClient.interface';
import { AxiosResponse } from 'axios';

export class HttpError implements HttpErrorInterface {
  status: number;
  statusText: string;
  headers?: Map<string, string>;
  error: ApiError;

  constructor(response?: AxiosResponse) {
    if (response) {
      this.status = response.status;
      this.statusText = response.statusText;
      this.headers = response.headers as Map<string, string>;
      this.error = HttpError.setError(response);
    } else {
      this.status = 499;
      this.statusText = generalError().message;
      this.error = generalError();
    }
  }

  private static setError(response: AxiosResponse): ApiError {
    return {
      message: response.data?.message || response.statusText || generalError().message,
      code: response.data?.code || response.status || generalError().code,
    };
  }
}

export const generalError = (): ApiError => {
  return {
    message: 'Data does not exist.',
    code: 499,
  };
};
