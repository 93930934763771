import { CryptoRepository } from './crypto.repository';
import { StorageKeyType } from '@/core/data/enums/storage.enum';
import logging from '@/core/logging/logging';

export class StorageRepository {
  private readonly crypto: CryptoRepository;
  private store: Storage;

  constructor(store: Storage) {
    this.store = store;
    this.crypto = new CryptoRepository();
  }

  set<T>(key: StorageKeyType, data: T): boolean {
    try {
      if (this.get<T>(key)) {
        this.remove(key);
      }
      this.store.setItem(key, this.crypto.encryptData<T>(data));
      return true;
    } catch (error) {
      logging.error(error);
      return false;
    }
  }

  get<T>(key: StorageKeyType): T | null {
    try {
      const chipper = this.store.getItem(key);
      if (chipper) {
        const object = this.crypto.decryptData<T>(chipper);
        if (object) {
          return object as T;
        } else {
          this.remove(key);
        }
      }
      return null;
    } catch (error) {
      logging.error(error);
      return null;
    }
  }

  remove(key: StorageKeyType): boolean {
    try {
      this.store.removeItem(key);
      return true;
    } catch (error) {
      logging.error(error);
      return false;
    }
  }
}
