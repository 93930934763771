
import { coreApp } from '@/core/app';
import { defineComponent, ref, watch, onMounted } from 'vue';
import { QueryParams } from '@/components/data/interfaces/navigation.interface';
import { useRoute } from 'vue-router';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const App = defineComponent({
  data: () => {
    return {
      reducePadding: false,
      closeModal: false,
    };
  },
  setup: () => {
    const route = useRoute();
    const loaded = ref(false);
    const loadTranslations = async (): Promise<void> => {
      await coreApp.translationService.setTranslations();
      loaded.value = true;
    };

    watch(
      () => route.query,
      (setQuery) => {
        const queryParams: QueryParams = setQuery || null;
        if (queryParams) {
          if (queryParams.app) {
            if (!queryParams.tab) {
              queryParams.tab = QueryTab.List;
            }
            coreApp.productService.setQueryParams(queryParams);
          } else {
            window.location.replace('https://gorenjc.si');
          }
        }
      },
    );

    onMounted(loadTranslations);
    return { loaded };
  },
  methods: {
    closeModalSelector(): void {
      this.closeModal = true;

      setTimeout(() => {
        this.closeModal = false;
      }, 550);
    },
    toggleReduce(value: boolean): void {
      this.reducePadding = value;
    },
  },
});
export default App;
