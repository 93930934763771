<template>
  <div class="filter-item" @click="selectFilter">
    <div class="filter-item-name">
      {{ translate(filter.name) }}
    </div>
    <div class="filter-item-checkbox">
      <div class="checked-box">
        <Icon v-if="filter.selected" name="checkbox" :size="IconSizeType.Small" />
        <div v-else class="empty-box" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { FilterItem } from '@/components/data/interfaces/selector.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Filter = defineComponent({
  components: {
    Icon,
  },
  mixins: [commonMethods],
  emits: ['select-filter'],
  props: {
    filter: {
      type: Object as PropType<FilterItem>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
  methods: {
    selectFilter() {
      if ((this.filter.id === '-1' && !this.filter.selected) || this.filter.id !== '-1') {
        this.$emit('select-filter', {
          type: this.type,
          filter: this.filter,
        });
      }
    },
  },
});
export default Filter;
</script>

<style lang="scss" scoped>
.filter-item {
  width: 100%;
  height: 16px;
  margin: 16px 0;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;

  &-name {
    font-size: 15px;
  }

  &-checkbox {
    justify-self: end;

    & .empty-box {
      background-color: transparent;
      border-radius: 2px;
      border: 1px solid var(--font-color-secondary);
      width: 16px;
      height: 16px;
    }
  }
}
</style>
