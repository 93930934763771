export enum LogLevel {
  Debug,
  Error,
  Info,
}

export interface LoggingInterface {
  debug(message: string, ...args: any[]): void;
  error(message: string, ...args: any[]): void;
  info(message: string, ...args: any[]): void;
  log(level: LogLevel, message: string, ...args: any[]): void;
}
