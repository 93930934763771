import { TranslationRepository } from '../../data/translation/translation.repository';
import logging from '@/core/logging/logging';

export class TranslationService {
  private repository: TranslationRepository;

  constructor(repository: TranslationRepository) {
    this.repository = repository;
  }

  translate(key: string): string {
    const translation = this.repository.translationsForKey(key);
    return translation ? TranslationService.replaceNewLineCharacter(translation) : key;
  }

  translateValues(key: string, values: string[]): string {
    const translation = this.repository.translationsForKey(key);
    let result = key;
    if (translation && values) {
      result = TranslationService.replaceNewLineCharacter(translation);
      return result.replace(/%s/g, () => {
        const value = values.shift();
        return value !== undefined ? value.toString() : '';
      });
    }
    return result;
  }

  async setTranslations(): Promise<void> {
    await this.repository.setTranslations();
  }

  private static replaceNewLineCharacter(key: string): string {
    return key.replace(/\\n/g, '\n');
  }
}
