import { Logger } from 'pino';

export class PinoLoggerAdapter {
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  debug(message: string, ...args: any[]): void {
    this.logger.debug(message, args);
  }

  error(message: string, ...args: any[]): void {
    this.logger.error(message, args);
  }

  info(message: string, ...args: any[]): void {
    this.logger.info(message, args);
  }
}
