import { ref, watch, getCurrentInstance } from 'vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';

export default (props: Readonly<{ show: boolean; selectorItems?: SortItem[] }>) => {
  const instance = getCurrentInstance();
  const showModal = ref(false);
  const isVisible = ref(false);
  const hideTimeout = ref({} as NodeJS.Timeout);

  const closeModal = (): void => {
    instance?.emit('close');
  };

  const clearHideTimeout = (): void => {
    if (hideTimeout.value) {
      clearTimeout(hideTimeout.value);
    }
  };

  watch(
    () => props.show,
    (value) => {
      const html = document.querySelector('html');
      const backdrop = document.querySelector('.backdrop');

      if (value) {
        html?.classList.add('modal-open');
        backdrop?.classList.add('show');
        isVisible.value = true;
        showModal.value = false;
        clearHideTimeout();
        setTimeout(() => {
          showModal.value = true;
        }, 50);
      } else {
        showModal.value = false;
        hideTimeout.value = setTimeout(() => {
          isVisible.value = false;
        }, 450);
        html?.classList.remove('modal-open');
        backdrop?.classList.remove('show');
      }
    },
  );

  return {
    showModal,
    isVisible,
    closeModal,
  };
};
