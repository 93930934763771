import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export class CryptoRepository {
  // The variable used to salt the encryption
  private readonly chilly = 'AP2&!KpA*eJwpR34J_49ThVtgMm_A_Qj9qKD';

  constructor() {}

  encryptData<T>(data: T): string {
    return AES.encrypt(JSON.stringify(data), this.chilly).toString();
  }

  decryptData<T>(chipper: string | string[]): T | T[] | null {
    try {
      if (Array.isArray(chipper)) {
        const objArray: T[] = [];
        chipper.forEach((element) => {
          const coded = AES.decrypt(element.toString(), this.chilly);
          objArray.push(JSON.parse(coded.toString(Utf8)) as T);
        });
        return typeof (objArray as T[]) && objArray.length > 0 ? objArray : null;
      } else {
        const coded = AES.decrypt(chipper.toString(), this.chilly);
        const obj = JSON.parse(coded.toString(Utf8)) as T;
        return typeof (obj as T) && obj ? obj : null;
      }
    } catch (error) {
      return null;
    }
  }
}
