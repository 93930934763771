<template>
  <div class="no-content">
    <div class="message">
      {{ translate(getMessage) }}
    </div>
    <div class="lost-in-space">
      <img src="images/lost-in-space.svg" :alt="translate(getMessage)" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { QueryTab } from '../data/enums/navigation.enum';
import { commonMethods } from '../Mixins/common.mixin';

const NoContent = defineComponent({
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  mixins: [commonMethods],
  computed: {
    getMessage(): string {
      if (this.tab === QueryTab.List) {
        return 'products_no_content_message';
      } else {
        return 'favorites_no_content_message';
      }
    },
  },
});
export default NoContent;
</script>

<style lang="scss" scoped>
.no-content {
  & .message {
    color: var(--font-color-secondary);
    line-height: 18px;
  }

  & .lost-in-space {
    padding: 32px 24px;
  }
}
</style>
