import { AppConfig, FirebaseConfig, RestAPIConfig } from '@/core/data/interfaces/config.interface';
import utils from '../utils';
import { StorageRepository } from '@/core/data/storages/storage.repository';
import { StorageKeyType } from '@/core/data/enums/storage.enum';
import logging from '@/core/logging/logging';

export class ConfigRepository {
  private readonly storage: StorageRepository;

  constructor(storage: StorageRepository) {
    this.storage = storage;
  }

  getApiEndpoint(): string {
    return process.env.VUE_APP_API_ENDPOINT || '';
  }

  setAppConfig(appConfig: AppConfig): void {
    this.storage.set<AppConfig>(StorageKeyType.AppConfig, appConfig);
  }

  getAppConfig(): AppConfig | null {
    return this.storage.get<AppConfig>(StorageKeyType.AppConfig) || null;
  }

  getLanguage(): string {
    const portalSettings = this.getAppConfig();
    if (portalSettings?.language) {
      return portalSettings.language;
    } else {
      return process.env.APP_DEFAULT_LANGUAGE || 'sl';
    }
  }

  getPageSize(): string {
    const pageSize = process.env.VUE_APP_PAGE_SIZE || 10;
    return `page_size=${pageSize}&`;
  }

  getRestApiConfig(): RestAPIConfig | null {
    const grant_type = process.env.VUE_APP_RESTAPI_GRANT_TYPE;
    const client_id = process.env.VUE_APP_RESTAPI_CLIENT_ID;
    const client_secret = process.env.VUE_APP_RESTAPI_CLIENT_SECRET;

    if (grant_type && client_id && client_secret) {
      return {
        grant_type,
        client_id,
        client_secret,
      };
    } else {
      logging.info('The configuration for Firebase is not valid');
      return null;
    }
  }

  getFirebaseConfig(): FirebaseConfig | null {
    const id = process.env.VUE_APP_FIREBASE_APP_ID;
    const key = process.env.VUE_APP_FIREBASE_API_KEY;
    const domain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN;
    const db = process.env.VUE_APP_FIREBASE_DATABASE_URL;
    const messaging_id = utils.extractIntegerFromEnv('VUE_APP_FIREBASE_MESSAGING_SENDER_ID');
    const measurement_id = process.env.VUE_APP_FIREBASE_MEASUREMENT_ID;
    const project_id = process.env.VUE_APP_FIREBASE_PROJECT_ID;
    const storage = process.env.VUE_APP_FIREBASE_STORAGE_BUCKET;

    if (id && key && domain && db && measurement_id && messaging_id && project_id && storage) {
      return {
        app_id: id,
        api_key: key,
        auth_domain: domain,
        database_url: db,
        messaging_sender_id: messaging_id,
        measurement_id,
        project_id,
        storage_bucket: storage,
      };
    } else {
      logging.info('The configuration for Firebase is not valid');
      return null;
    }
  }

  getFirebaseVapid(): string {
    return process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY || '';
  }
}
