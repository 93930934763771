<template>
  <div class="footer">
    <div class="search-space">
      <div class="search-icon">
        <Icon name="search" :size="IconSizeType.XLarge" />
      </div>
      <div class="search-input">
        <input
          ref="searchInput"
          :placeholder="translate('search_input_placeholder')"
          @focus="addMobileFix"
          @blur="removeMobileFix"
          :value="searchQuery"
          @input="turnSearch"
        />
      </div>
      <div v-if="searchQuery.length > 0" class="clear-search" @click="clearSearch">
        <Icon name="close" :size="IconSizeType.Small" />
      </div>
      <div v-else class="filter-icon" @click="showSelector">
        <Icon name="filter" :size="IconSizeType.Small" />
      </div>
    </div>
    <FilterSelector :show="isSelectorOpen" @trigger-filter="triggerFilter" @close="closeSelector" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, getCurrentInstance } from 'vue';
import Icon from '@/components/Parts/Icon.vue';
import FilterSelector from '@/components/Filtering/FilterSelector.vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Footer = defineComponent({
  components: {
    Icon,
    FilterSelector,
  },
  props: {
    closeModal: {
      type: Boolean,
      required: true,
    },
    hiddenKeyboard: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['trigger-filter', 'trigger-search'],
  mixins: [commonMethods],
  data: () => {
    return {
      IconSizeType,
      isSelectorOpen: false,
    };
  },
  setup: () => {
    const instance = getCurrentInstance();
    const searching = ref(false);
    const searchQuery = ref('');
    const searchInput = ref<HTMLElement | null>(null);
    const html = document.querySelector('html');

    const turnSearch = (value: any) => {
      if (value.inputType === 'deleteContentBackward') {
        searchQuery.value = searchQuery.value.substring(0, searchQuery.value.length - 1);
        if (searchQuery.value.length === 0) {
          instance?.emit('trigger-search', '');
          return;
        }
      } else {
        searchQuery.value += value.data || '';
      }
    };

    const handlePaste = (event: any) => {
      // @ts-ignore
      const paste = (event?.clipboardData || window.clipboardData).getData('text');
      if (paste?.length) {
        searchQuery.value = paste;
      }
      event.preventDefault();
    };

    const submitSearch = (event: any) => {
      if (event.which === 13 || event.code === 'Enter') {
        if (searchQuery.value.length >= 2) {
          instance?.emit('trigger-search', searchQuery.value);
        }
      }
    };

    const addMobileFix = () => {
      window.addEventListener('keypress', submitSearch);
      window.addEventListener('paste', handlePaste);
      html?.classList.add('mobile-fix');

      setTimeout(() => {
        searching.value = true;
      }, 750);
    };

    const removeMobileFix = () => {
      window.removeEventListener('keypress', submitSearch);
      window.removeEventListener('paste', handlePaste);
      html?.classList.remove('mobile-fix');
      searching.value = false;
    };

    return {
      searchInput,
      searchQuery,
      turnSearch,
      addMobileFix,
      removeMobileFix,
      searching,
    };
  },
  methods: {
    clearSearch() {
      this.searchQuery = '';
      this.$emit('trigger-search', '');
    },
    triggerFilter() {
      this.closeSelector();
      this.$emit('trigger-filter');
    },
    showSelector(): void {
      this.isSelectorOpen = true;
    },
    closeSelector(): void {
      this.isSelectorOpen = false;
    },
  },
  watch: {
    hiddenKeyboard(value) {
      if (value && this.searching) {
        const searchInput = (this.$refs.searchInput as HTMLElement) || null;
        if (searchInput) {
          searchInput.blur();
        }
      }
    },
    closeModal(value) {
      if (value) {
        this.closeSelector();
      }
    },
  },
});
export default Footer;
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 4px 30px 0 var(--shadow-color-primary);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  padding: 16px 16px 32px;

  & .search-space {
    background-color: var(--background-color-secondary);
    display: inline-grid;
    grid-template-columns: max-content auto max-content;
    align-items: center;
    height: 48px;
    border-radius: 8px;
    width: 100%;
    padding: 0 0 0 16px;

    & .search-input {
      padding: 0 16px;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 100%;
      overflow: hidden;

      input {
        color: var(--font-color-secondary);
        background-color: transparent;
        font-size: 15px;
        letter-spacing: 0.14px;
        height: 24px;
        border: 0;
        line-height: 24px;
        flex: 1 1 auto;
        min-width: 0;
        margin-bottom: 0;
        -webkit-user-select: text;

        &:focus {
          color: var(--font-color);
        }
      }
    }

    & .filter-icon,
    & .clear-search {
      padding: 0 16px;
      border-left: 1px solid var(--background-color-seperator);
      height: 24px;
      display: flex;
    }
  }
}
</style>
