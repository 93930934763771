import axios from 'axios';
import logging from '@/core/logging/logging';
import { StorageKeyType } from '@/core/data/enums/storage.enum';
import { StorageRepository } from '@/core/data/storages/storage.repository';
import { TokenInfo } from '@/core/data/interfaces/auth.interface';
import {
  AppRequestConfig,
  HttpRequestHeaders,
  HttpResponse,
} from '@/core/network/http/httpClient.interface';
import { ConfigRepository } from '@/core/data/config/config.repository';
import { RestAPIConfig } from '@/core/data/interfaces/config.interface';

export class TokenRepository {
  private readonly config: ConfigRepository;
  private readonly storage: StorageRepository;
  private readonly tokenEndpoint = '/auth/token/';
  private static tokenHandler: Promise<void> | null;

  constructor(config: ConfigRepository, storage: StorageRepository) {
    this.config = config;
    this.storage = storage;
    TokenRepository.tokenHandler = null;
  }

  private async getNewToken(restAPIConfig: RestAPIConfig): Promise<HttpResponse<TokenInfo>> {
    const apiEndPoint = this.config.getApiEndpoint();
    const config: AppRequestConfig = this.getAuthorizationConfig();
    return axios.post<TokenInfo>(`${apiEndPoint}${this.tokenEndpoint}`, restAPIConfig, config);
  }

  private async tokenHandlerMethod(restAPIConfig: RestAPIConfig): Promise<void> {
    try {
      const response = await this.getNewToken(restAPIConfig);
      if (response?.data) {
        this.setTokenInfo(response.data);
      }
      TokenRepository.tokenHandler = null;
    } catch (error) {
      logging.error('Could not get new AuthToken');
      this.removeToken();
      throw error;
    }
  }

  setTokenInfo(tokenInfo: TokenInfo | null): void {
    if (tokenInfo) {
      this.storage.set<TokenInfo>(StorageKeyType.TokenInfo, tokenInfo);
    }
  }

  private getTokenInfo(): TokenInfo | null {
    return this.storage.get<TokenInfo>(StorageKeyType.TokenInfo);
  }

  private removeToken(): void {
    this.storage.remove(StorageKeyType.TokenInfo);
  }

  private getHeadersFromToken(): HttpRequestHeaders {
    const headers: HttpRequestHeaders = {
      'content-type': 'application/json',
    };

    // Set Authorization header
    const tokenInfo = this.getTokenInfo();
    if (tokenInfo) {
      headers.authorization = `${tokenInfo.token_type} ${tokenInfo.access_token}`;
    }
    return headers;
  }

  async refreshToken(): Promise<TokenInfo | null> {
    try {
      const restAPIConfig = this.config.getRestApiConfig();
      if (restAPIConfig) {
        const response = await this.getNewToken(restAPIConfig);
        if (response?.data) {
          return response.data;
        }
      }
      return null;
    } catch (error) {
      logging.error('Could not get new AuthToken');
      this.removeToken();
      throw error;
    }
  }

  getAuthorizationConfig(config?: AppRequestConfig): AppRequestConfig {
    if (config) {
      return {
        ...config,
        headers: this.getHeadersFromToken(),
      };
    } else {
      return {
        headers: this.getHeadersFromToken(),
        retryRequest: false,
      };
    }
  }
}
