<template>
  <div class="spinner-circular" role="progressbar" style="animation-duration: 1400ms">
    <svg viewBox="24 24 48 48" style="animation-duration: 1400ms">
      <circle
        transform="translate(0,0)"
        cx="48"
        cy="48"
        r="20"
        style="animation-duration: 1400ms"
      ></circle>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
};
</script>

<style scoped lang="scss">
.spinner-circular {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--font-color-primary);
  user-select: none;
  animation: 0s linear 0s infinite normal none running spinner-circular;
  z-index: 999;

  svg {
    left: 0;
    top: 0;
    transform-origin: center center;
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateZ(0px);

    circle {
      transform-origin: 0 0;
      animation: 0s ease-in-out 0s infinite normal none running spinner-circular-inner;
      stroke: var(--font-color-primary);
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0;
      stroke-width: 5.6;
      fill: none;
    }
  }

  @keyframes spinner-circular {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner-circular-inner {
    0% {
      stroke-dasharray: 1px, 200px;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -15px;
    }
    100% {
      stroke-dasharray: 100px, 200px;
      stroke-dashoffset: -125px;
    }
  }
}
</style>
