import { HttpClient } from '@/core/network/http/httpClient';
import { ConfigRepository } from '@/core/data/config/config.repository';
import { StorageRepository } from '@/core/data/storages/storage.repository';

import { ProductApi } from '@/core/network/api/product.api';

import { ProductService } from '@/core/domain/product/product.service';
import { ProductRepository } from '@/core/data/product/product.repository';

import { TranslationRepository } from '@/core/data/translation/translation.repository';
import { TranslationService } from '@/core/domain/translation/translation.service';
import { TokenRepository } from '@/core/data/token/token.repository';

class CoreApp {
  // Initialization of helpers used only by services (not exposed to UI)
  private readonly httpClient: HttpClient;
  private readonly localStorage: StorageRepository;
  private readonly tokenRepository: TokenRepository;

  // Initialization of services that are used by UI
  public readonly config: ConfigRepository;
  public readonly productService: ProductService;
  public readonly translationService: TranslationService;

  constructor() {
    // Builds dependency graph
    this.localStorage = new StorageRepository(window.localStorage);
    this.config = new ConfigRepository(this.localStorage);
    this.tokenRepository = new TokenRepository(this.config, this.localStorage);

    // Create an instance of HttpClient object
    this.httpClient = new HttpClient(this.tokenRepository);

    // Localization service
    const translationRepository = new TranslationRepository(this.config);
    this.translationService = new TranslationService(translationRepository);

    const productApi = new ProductApi(this.httpClient);
    const productRepository = new ProductRepository(productApi, this.config, this.localStorage);
    this.productService = new ProductService(productRepository);
  }
}

export const translate = (translationKey: string): string => {
  return coreApp.translationService.translate(translationKey);
};

export const translateValues = (translationKey: string, values: string[]): string => {
  return coreApp.translationService.translateValues(translationKey, values);
};

export const coreApp = new CoreApp();
