import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import logging from '@/core/logging/logging';
import { AppAxiosError, AppPromises, AppRequestConfig, HttpResponse } from './httpClient.interface';
import { HttpError } from './httpError';
import { TokenRepository } from '@/core/data/token/token.repository';
import { TokenInfo } from '@/core/data/interfaces/auth.interface';

export class HttpClient {
  private readonly tokenRepository: TokenRepository;
  private readonly instance: AxiosInstance;
  private failedRequests: AppPromises[] = [];
  private isRefreshing = false;

  constructor(tokenRepository: TokenRepository) {
    this.tokenRepository = tokenRepository;
    this.instance = axios.create({
      validateStatus: (status) => status < 400,
    });
    this.setResponseInterceptors();
  }

  async get<R>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<R>> {
    config = this.tokenRepository.getAuthorizationConfig(config as AppRequestConfig);
    logging.debug('.get(...)', { url });
    return this.instance.get<R>(url, config);
  }

  async post<R, T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<HttpResponse<R>> {
    config = this.tokenRepository.getAuthorizationConfig(config as AppRequestConfig);
    logging.debug('.post(...)', { url });
    return this.instance.post<R>(url, data, config);
  }

  async put<R, T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<HttpResponse<R>> {
    config = this.tokenRepository.getAuthorizationConfig(config as AppRequestConfig);
    logging.debug('.put(...)', { url });
    return this.instance.put<R>(url, data, config);
  }

  async delete<R>(url: string, config?: AxiosRequestConfig): Promise<HttpResponse<R>> {
    config = this.tokenRepository.getAuthorizationConfig(config as AppRequestConfig);
    logging.debug('.delete(...)', { url });
    return this.instance.delete<R>(url, config);
  }

  async patch<R, T>(url: string, data?: T, config?: AxiosRequestConfig): Promise<HttpResponse<R>> {
    config = this.tokenRepository.getAuthorizationConfig(config as AppRequestConfig);
    logging.debug('.patch(...)', { url });
    return this.instance.patch<R>(url, data, config);
  }

  private setResponseInterceptors(): void {
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error: AppAxiosError) => {
        if (error.response?.status === 401 && !error.config.retryRequest) {
          if (this.isRefreshing) {
            try {
              const tokenInfo: TokenInfo | null = await new Promise((resolve, reject) => {
                this.failedRequests.push({ resolve, reject });
              });
              error.config.headers.authorization = `${tokenInfo?.token_type} ${tokenInfo?.access_token}`;
              return this.instance(error.config);
            } catch (e) {
              return e;
            }
          }
          this.isRefreshing = true;
          error.config.retryRequest = true;
          return new Promise((resolve, reject) => {
            this.tokenRepository
              .refreshToken()
              .then((tokenInfo: TokenInfo | null) => {
                this.tokenRepository.setTokenInfo(tokenInfo);
                error.config.headers.authorization = `${tokenInfo?.token_type} ${tokenInfo?.access_token}`;
                this.isRefreshing = false;
                this.processQueue(null, tokenInfo);
                resolve(this.instance(error.config));
              })
              .catch((e) => {
                this.processQueue(e);
                reject(error.response);
              });
          });
        }
        throw new HttpError(error.response);
      },
    );
  }

  private processQueue(error: AppAxiosError | null, token: TokenInfo | null = null) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });
    this.failedRequests = [];
  }
}
