import logging from '@/core/logging/logging';

const extractIntegerFromEnv = (key: string, defaultValue?: number): number | undefined => {
  const config = process.env[key];
  if (!config) return defaultValue;

  const value = parseInt(config, 10);
  if (Number.isNaN(value)) {
    logging.info(
      `Environment variable ${key} with value (${config}) is not a valid number. Defaulting to ${defaultValue}.`,
    );
    return defaultValue;
  }
  return value;
};

const extractBooleanFromEnv = (key: string, defaultValue?: boolean): boolean => {
  const useDefault = () => {
    if (typeof defaultValue === 'undefined') {
      logging.info(
        `Environment variable ${key} with value ("${config}") does not seem to be a valid boolean. Will use false instead.`,
      );
      return false;
    } else {
      return defaultValue;
    }
  };

  const config = process.env[key];
  if (!config) return useDefault();

  const value = config.toLowerCase();
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value === '1') return true;
  if (value === '0') return false;
  return useDefault();
};

export default { extractBooleanFromEnv, extractIntegerFromEnv };
