import { Category, Company, DtoProductResponse } from '@/core/data/interfaces/product.interface';
import { ProductApi } from '@/core/network/api/product.api';
import { HttpError } from '@/core/network/http/httpError';
import { ConfigRepository } from '@/core/data/config/config.repository';
import logging from '@/core/logging/logging';
import {
  FilterItem,
  FilterSelections,
  SortItem,
} from '@/components/data/interfaces/selector.interface';
import { StorageRepository } from '@/core/data/storages/storage.repository';
import { SortingTypeBy } from '@/components/data/enums/selector.enum';
import { StorageKeyType } from '@/core/data/enums/storage.enum';
import { QueryParams } from '@/components/data/interfaces/navigation.interface';

export class ProductRepository {
  private readonly api: ProductApi;
  private readonly config: ConfigRepository;
  private readonly storage: StorageRepository;

  constructor(api: ProductApi, config: ConfigRepository, storage: StorageRepository) {
    this.api = api;
    this.config = config;
    this.storage = storage;
  }

  private getOrderingQuery(type: SortingTypeBy) {
    return `ordering=${type}&`;
  }

  private getSelectedFilter(filterItems: FilterItem[]): string {
    let queryFilterItems = '';

    filterItems.forEach((item) => {
      if (item.id !== '-1' && item.selected) {
        queryFilterItems += `,${item.id}`;
      }
    });

    return queryFilterItems.length > 0 ? queryFilterItems.substr(1) : queryFilterItems;
  }

  private getFilteringQuery() {
    const filterSelection = this.getFilterSelections();

    if (filterSelection) {
      const categoryFilters = encodeURIComponent(
        this.getSelectedFilter(filterSelection.categories),
      );
      const companyFilters = encodeURIComponent(this.getSelectedFilter(filterSelection.companies));
      if (categoryFilters.length > 0 && companyFilters.length > 0) {
        return `categories=${categoryFilters}&companies=${companyFilters}&`;
      } else if (categoryFilters.length > 0) {
        return `categories=${categoryFilters}&`;
      } else if (companyFilters.length > 0) {
        return `companies=${companyFilters}&`;
      }
    }

    return '';
  }

  private getSearchQuery(query?: string): string {
    if (query && query.length >= 2) {
      return `search=${query}&`;
    }
    return '';
  }

  private getTokenInfo(): string {
    const queryParams = this.getQueryParams();
    return queryParams?.token ? `token=${queryParams.token}&` : '';
  }

  async getAllProducts(sort: SortItem, query?: string): Promise<DtoProductResponse | null> {
    try {
      const apiEndpoint = this.config.getApiEndpoint();
      const queryUrl = apiEndpoint.concat(
        `/catalogue/products/?${this.getFilteringQuery()}${this.getOrderingQuery(
          sort.type,
        )}${this.getSearchQuery(query)}${this.getTokenInfo()}${this.config.getPageSize()}a/`,
      );
      const response = await this.api.getAllProducts(queryUrl);
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  async getAllFavoriteProducts(): Promise<DtoProductResponse | null> {
    try {
      const apiEndpoint = this.config.getApiEndpoint();
      const queryParams = this.getQueryParams();
      const queryUrl = apiEndpoint.concat(
        `/catalogue/products/favorites/${queryParams?.token || ''}/`,
      );
      const response = await this.api.getAllProducts(queryUrl);
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  async getFavoriteProducts(sort: SortItem, query?: string): Promise<DtoProductResponse | null> {
    try {
      const apiEndpoint = this.config.getApiEndpoint();
      const queryParams = this.getQueryParams();
      const queryUrl = apiEndpoint.concat(
        `/catalogue/products/favorites/${
          queryParams?.token || ''
        }/?${this.getFilteringQuery()}${this.getOrderingQuery(sort.type)}${this.getSearchQuery(
          query,
        )}${this.config.getPageSize()}a/`,
      );
      const response = await this.api.getAllProducts(queryUrl);
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  async getNextPage(nextPage: string): Promise<DtoProductResponse | null> {
    try {
      const response = await this.api.getAllProducts(nextPage);
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  async getCategories(): Promise<Category[] | null> {
    try {
      const response = await this.api.getCategories(this.config.getApiEndpoint());
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  async getCompanies(): Promise<Company[] | null> {
    try {
      const response = await this.api.getCompanies(this.config.getApiEndpoint());
      return response?.data || null;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }

  getQueryParams(): QueryParams | null {
    return this.storage.get<QueryParams>(StorageKeyType.QueryParams);
  }

  setQueryParams(data: QueryParams): boolean {
    this.storage.remove(StorageKeyType.QueryParams);
    return this.storage.set<QueryParams>(StorageKeyType.QueryParams, data);
  }

  getFilterSelections(): FilterSelections | null {
    return this.storage.get<FilterSelections>(StorageKeyType.FilterSelections);
  }

  setFilterSelections(data: FilterSelections): boolean {
    return this.storage.set<FilterSelections>(StorageKeyType.FilterSelections, data);
  }

  removeFilterSelections(): boolean {
    return this.storage.remove(StorageKeyType.FilterSelections);
  }

  async toggleFavorite(isFavorite: boolean, productId: string): Promise<boolean> {
    try {
      const apiEndpoint = this.config.getApiEndpoint();
      const queryParams = this.getQueryParams();
      const response = isFavorite
        ? await this.api.removeFromFavorite(apiEndpoint, productId, queryParams?.token || '')
        : await this.api.addToFavorite(apiEndpoint, productId, queryParams?.token || '');
      return response && response.status >= 200 && response.status < 300;
    } catch (e) {
      const httpError = e as HttpError;
      logging.error(`${httpError.status} => ${httpError.statusText}`);
      throw httpError.error;
    }
  }
}
