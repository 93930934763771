<template>
  <div class="product-favorite" @click="toggleFavorite">
    <div class="favorite-area">
      <div class="favorite-animation">
        <div :class="{ show: favorite }">
          <Icon name="heart_full" :size="IconSizeType.Large" />
        </div>
        <div :class="{ show: !favorite }">
          <Icon name="heart_empty" :size="IconSizeType.Large" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { Product } from '@/core/data/interfaces/product.interface';
import { coreApp } from '@/core/app';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const Favorite = defineComponent({
  name: 'Favorite',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
  },
  mounted() {
    if (this.tab === QueryTab.Favorites) {
      this.favorite = true;
    } else {
      this.favorite = this.product.is_favorite;
    }
  },
  data: () => {
    return {
      IconSizeType,
      favorite: false,
      loading: false,
    };
  },
  emits: ['toggle-favorite'],
  methods: {
    async toggleFavorite() {
      if (!this.loading) {
        this.loading = true;

        const response = await coreApp.productService.toggleFavorite(this.favorite, this.product);
        if (response.data) {
          this.favorite = !this.favorite;
          this.$emit('toggle-favorite', this.favorite);
        }
        this.loading = false;
      }
    },
  },
});
export default Favorite;
</script>

<style lang="scss" scoped>
.product-favorite {
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 10;

  &:focus,
  &:active {
    outline: none !important;
  }

  & .favorite-area {
    background-color: #ffffff;
    position: relative;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    box-shadow: 0 2px 4px 0 var(--shadow-color-icon-top),
      0 1px 1px 0 var(--shadow-color-icon-bottom);

    & .favorite-animation {
      padding: 10px;
      position: relative;

      & div {
        opacity: 0;
        position: absolute;
        transform: scale(0);
        top: 0;
        left: 0;
        transition: opacity var(--duration) var(--cubic-bezier) 0s,
          transform var(--duration) var(--cubic-bezier) 0s,
          -webkit-transform var(--duration) var(--cubic-bezier) 0s,
          -moz-transform var(--duration) var(--cubic-bezier) 0s,
          -o-transform var(--duration) var(--cubic-bezier) 0s;

        &.show {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
</style>
