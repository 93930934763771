
import { defineComponent, PropType } from 'vue';
import { NavItem } from '@/components/data/interfaces/navigation.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const TabItem = defineComponent({
  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  mixins: [commonMethods],
  computed: {
    isCounting(): boolean {
      if (this.navItem.type === QueryTab.Favorites) {
        return this.count > 0;
      }
      return false;
    },
  },
});
export default TabItem;
