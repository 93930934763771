import { translate, translateValues } from '@/core/app';

export const commonMethods = {
  methods: {
    translate: (key: string): string => {
      return translate(key);
    },
    localizeReplaceValues: (key: string, values: string[]): string => {
      return translateValues(key, values);
    },
  },
};
