
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { FilterItem } from '@/components/data/interfaces/selector.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Filter = defineComponent({
  components: {
    Icon,
  },
  mixins: [commonMethods],
  emits: ['select-filter'],
  props: {
    filter: {
      type: Object as PropType<FilterItem>,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
  methods: {
    selectFilter() {
      if ((this.filter.id === '-1' && !this.filter.selected) || this.filter.id !== '-1') {
        this.$emit('select-filter', {
          type: this.type,
          filter: this.filter,
        });
      }
    },
  },
});
export default Filter;
