
import { coreApp, translateValues, translate } from '@/core/app';
import { defineComponent, PropType, ref, toRefs, onMounted, getCurrentInstance } from 'vue';
import { Product } from '@/core/data/interfaces/product.interface';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Favorite from '@/components/Product/Favorite.vue';
import Image from '@/components/Parts/Image.vue';
import Icon from '@/components/Parts/Icon.vue';
import { CategoryType } from '@/core/data/enums/product.enum';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const ProductItem = defineComponent({
  name: 'ProductItem',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    Image,
    Icon,
    Favorite,
  },
  emits: ['remove-product', 'count-favorite'],
  setup: (props) => {
    const instance = getCurrentInstance();
    const { product } = toRefs(props);
    const offerTimeLeft = ref('');
    const isProductAvailable = ref();
    const getValidTo = () => {
      offerTimeLeft.value = '';
      offerTimeLeft.value = getOfferTimeLeft(product.value);
      isProductAvailable.value = coreApp.productService.isProductAvailable(product.value);
      if (!isProductAvailable.value) {
        setTimeout(() => {
          instance?.emit('remove-product', product.value);
        }, 100);
      }
    };

    onMounted(getValidTo);

    const getTimeValidTo = (validTo: string): number => {
      return new Date(validTo).getTime();
    };

    const getOfferTimeLeft = (product: Product): string => {
      const now = new Date().getTime();
      const validTo = getTimeValidTo(product.valid_to);
      return getTimeUnit(validTo - now);
    };

    const getTimeUnit = (distance: number): string => {
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      if (days && days > 0) {
        return translateValues(
          days > 1 ? 'products_item_valid_to_plural' : 'products_item_valid_to_singular',
          [days.toString(10)],
        );
      } else {
        return translate('products_item_valid_last_day');
      }
    };

    return {
      offerTimeLeft,
      isProductAvailable,
      getValidTo,
    };
  },
  data: () => {
    return {
      IconSizeType,
      nameLength: 42,
    };
  },
  methods: {
    truncate(content: string) {
      if (content && content.length > this.nameLength) {
        return content.substring(0, this.nameLength - 3) + '...';
      }
      return content;
    },
    roundUp: (price: number) => {
      return price.toFixed(2);
    },
    toggleFavorite(favorite: boolean) {
      if (this.tab === QueryTab.Favorites && !favorite) {
        this.$emit('remove-product', this.product);
        this.$emit('count-favorite', false);
      } else {
        this.$emit('count-favorite', favorite);
      }
    },
    validateDiscountPrice(): boolean {
      const discountPrice = this.product.discount_price;
      const originalPrice = this.product.price;

      return !!(discountPrice && discountPrice < originalPrice);
    },
  },
  computed: {
    getSource(): string {
      return this.product.thumb_image_links?.[0] || this.product.image_links?.[0];
    },
    categoryIcon(): string {
      return `${this.product.category?.name || CategoryType.Unknown}`;
    },
    categoryBackground(): { backgroundColor: string } {
      const categoryName = this.product.category?.name.replace('_', '-') || CategoryType.Unknown;
      return {
        backgroundColor: `var(--category-${categoryName.toLowerCase()}-shape)`,
      };
    },
    getDiscountPrice(): number {
      return this.validateDiscountPrice() ? this.product.discount_price : this.product.price;
    },
    isOldPrice(): boolean {
      return this.validateDiscountPrice();
    },
  },
});
export default ProductItem;
