
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { SortItem } from '@/components/data/interfaces/selector.interface';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Sorting = defineComponent({
  components: {
    Icon,
  },
  emits: ['open-sort-selector'],
  mixins: [commonMethods],
  props: {
    sort: {
      type: Object as PropType<SortItem>,
      required: true,
    },
  },
  data: () => {
    return {
      IconSizeType,
    };
  },
  methods: {},
});
export default Sorting;
