
import { defineComponent, PropType } from 'vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Icon from '@/components/Parts/Icon.vue';
import { Product } from '@/core/data/interfaces/product.interface';
import { coreApp } from '@/core/app';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const Favorite = defineComponent({
  name: 'Favorite',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    Icon,
  },
  mounted() {
    if (this.tab === QueryTab.Favorites) {
      this.favorite = true;
    } else {
      this.favorite = this.product.is_favorite;
    }
  },
  data: () => {
    return {
      IconSizeType,
      favorite: false,
      loading: false,
    };
  },
  emits: ['toggle-favorite'],
  methods: {
    async toggleFavorite() {
      if (!this.loading) {
        this.loading = true;

        const response = await coreApp.productService.toggleFavorite(this.favorite, this.product);
        if (response.data) {
          this.favorite = !this.favorite;
          this.$emit('toggle-favorite', this.favorite);
        }
        this.loading = false;
      }
    },
  },
});
export default Favorite;
