<template>
  <figure v-lazyload class="image-wrapper">
    <div class="image-loading">
      <Spinner />
    </div>
    <img class="image-item" :data-url="handleImage" :src="handleImage" :alt="alt" />
  </figure>
</template>

<script>
import { defineComponent } from 'vue';
import Spinner from '@/components/Parts/Spinner';

const Image = defineComponent({
  name: 'Image',
  props: {
    source: {
      type: String,
      required: true,
    },
    alt: String,
  },
  components: {
    Spinner,
  },
  computed: {
    handleImage() {
      return this.source;
    },
  },
});
export default Image;
</script>

<style scoped lang="scss">
.image {
  &-wrapper {
    margin: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: inherit;
    background-color: var(--background-color-secondary);

    &.hide {
      background-color: transparent;
      .image {
        &-item {
          opacity: 0;
        }

        &-loading {
          display: inherit;
        }
      }
    }
  }

  &-loading {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -1em;
    margin-left: -1em;
    width: 2em;
    height: 2em;
  }

  &-item {
    width: auto;
    height: auto;
    transition: opacity var(--duration) ease-in-out;

    &.landscape {
      width: 100% !important;
    }

    &.portrait {
      height: 100% !important;
    }
  }
}
</style>
