
import { defineComponent } from 'vue';
import { QueryTab } from '../data/enums/navigation.enum';
import { commonMethods } from '../Mixins/common.mixin';

const NoContent = defineComponent({
  props: {
    tab: {
      type: String,
      required: true,
    },
  },
  mixins: [commonMethods],
  computed: {
    getMessage(): string {
      if (this.tab === QueryTab.List) {
        return 'products_no_content_message';
      } else {
        return 'favorites_no_content_message';
      }
    },
  },
});
export default NoContent;
