import { DtoProductResponse, Product } from '@/core/data/interfaces/product.interface';
import { ProductRepository } from '@/core/data/product/product.repository';
import {
  FilterItem,
  FilterSelections,
  SortItem,
} from '@/components/data/interfaces/selector.interface';
import { ApiError } from '@/core/network/http/httpClient.interface';
import { Result } from '@/core/data/interfaces/app.interface';
import { generalError } from '@/core/network/http/httpError';
import { QueryParams } from '@/components/data/interfaces/navigation.interface';
import { QueryTab } from '@/components/data/enums/navigation.enum';
import { TranslationService } from '@/core/domain/translation/translation.service';

export class ProductService {
  private readonly repository: ProductRepository;

  constructor(repository: ProductRepository) {
    this.repository = repository;
    this.repository.removeFilterSelections();
  }

  setQueryParams(queryParams: QueryParams | null): boolean {
    if (queryParams) {
      return this.repository.setQueryParams(queryParams);
    }
    return false;
  }

  getQueryParams(): QueryParams | null {
    return this.repository.getQueryParams();
  }

  async getAllProducts(
    sort: SortItem,
    tab: QueryTab,
    query?: string,
  ): Promise<Result<DtoProductResponse>> {
    try {
      const response =
        tab === QueryTab.Favorites
          ? await this.repository.getFavoriteProducts(sort, query)
          : await this.repository.getAllProducts(sort, query);
      return response ? { data: response } : { error: generalError() };
    } catch (apiError) {
      return {
        error: apiError as ApiError,
      };
    }
  }

  async getNumberOfFavorites(): Promise<number> {
    try {
      const response = await this.repository.getAllFavoriteProducts();
      if (response) {
        return response.count;
      }
      return 0;
    } catch (e) {
      return 0;
    }
  }

  async toggleFavorite(isFavorite: boolean, product: Product): Promise<Result<boolean>> {
    try {
      const response = await this.repository.toggleFavorite(isFavorite, product.id);
      return response ? { data: response } : { error: generalError() };
    } catch (apiError) {
      return {
        error: apiError as ApiError,
      };
    }
  }

  async getNextPage(nextPage: string): Promise<Result<DtoProductResponse>> {
    try {
      const response = await this.repository.getNextPage(nextPage);
      return response ? { data: response } : { error: generalError() };
    } catch (apiError) {
      return {
        error: apiError as ApiError,
      };
    }
  }

  private getTimeValidTo(validTo: string): number {
    return new Date(validTo).getTime();
  }

  private getTimeValidFrom(validFrom: string): number {
    return new Date(validFrom).getTime();
  }

  isProductAvailable(product: Product): boolean {
    const now = new Date().getTime();

    return (
      this.getTimeValidFrom(product.valid_from) < now && this.getTimeValidTo(product.valid_to) > now
    );
  }

  private async mapCategoriesToFilters(): Promise<FilterItem[]> {
    try {
      const response = await this.repository.getCategories();
      if (response?.length) {
        return response.map((item) => {
          return {
            id: item.id,
            name: item.display_name,
            selected: false,
          };
        });
      }
      return [];
    } catch (apiError) {
      return [];
    }
  }

  private async mapCompaniesToFilters(): Promise<FilterItem[]> {
    try {
      const response = await this.repository.getCompanies();
      if (response?.length) {
        return response.map((item) => {
          return {
            id: item.id,
            name: item.display_title,
            selected: false,
          };
        });
      }
      return [];
    } catch (apiError) {
      return [];
    }
  }

  async getFilteringItems(): Promise<FilterSelections> {
    let filterSelections = this.repository.getFilterSelections();

    if (!filterSelections) {
      const categories = await this.mapCategoriesToFilters();
      categories.unshift({ id: '-1', name: 'filtering_section_categories_all', selected: true });
      const companies = await this.mapCompaniesToFilters();
      companies.unshift({ id: '-1', name: 'filtering_section_company_all', selected: true });

      filterSelections = {
        categories,
        companies,
      };

      this.repository.setFilterSelections(filterSelections);
    }
    return filterSelections;
  }

  saveFilterItems(filterItems: FilterSelections | null): boolean {
    if (filterItems) {
      return this.repository.setFilterSelections(filterItems);
    }
    return false;
  }
}
