<template>
  <div class="toast-error" :class="{ show: showToastError }">
    <div class="message">
      <Icon name="error" :size="IconSizeType.XLarge" />
      <span class="message-text" v-html="translate('general_error')" />
    </div>
    <div class="close" @click="closeError">
      <Icon name="close-toast" />
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Parts/Icon.vue';
import { IconSizeType } from '../data/enums/icon.enum';
import { commonMethods } from '../Mixins/common.mixin';

export default {
  name: 'ToastError',
  components: { Icon },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [commonMethods],
  emits: ['close-error'],
  data: () => {
    return {
      IconSizeType,
      showToastError: false,
    };
  },
  mounted() {
    this.showToastError = true;

    setTimeout(() => {
      this.closeError();
    }, 3000);
  },
  methods: {
    closeError() {
      this.showToastError = false;
      setTimeout(() => {
        this.$emit('close-error');
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-error {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  padding: 24px 16px;
  background-color: var(--background-color-error);
  border-radius: 0 0 8px 8px;
  z-index: 999;
  opacity: 0;
  transition: opacity var(--duration-fast) var(--cubic-bezier) 0s;

  &.show {
    opacity: 1;
  }

  & .message {
    display: grid;
    align-items: center;
    grid-template-columns: max-content auto;

    &-text {
      margin-left: 10px;
      white-space: pre-line;
      color: #ffffff;
      line-height: 22px;
      font-size: 12px;
      letter-spacing: 0.27px;
    }
  }

  & .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }
}
</style>
