<template>
  <div v-if="loaded" id="WebApp">
    <span class="backdrop" @click="closeModalSelector" />
    <div class="container" :class="{ reduce: reducePadding }">
      <router-view v-slot="{ Component }">
        <transition name="routerAnimation" appear>
          <component :is="Component" :closeModal="closeModal" @reduce-padding="toggleReduce" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
import { coreApp } from '@/core/app';
import { defineComponent, ref, watch, onMounted } from 'vue';
import { QueryParams } from '@/components/data/interfaces/navigation.interface';
import { useRoute } from 'vue-router';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const App = defineComponent({
  data: () => {
    return {
      reducePadding: false,
      closeModal: false,
    };
  },
  setup: () => {
    const route = useRoute();
    const loaded = ref(false);
    const loadTranslations = async (): Promise<void> => {
      await coreApp.translationService.setTranslations();
      loaded.value = true;
    };

    watch(
      () => route.query,
      (setQuery) => {
        const queryParams: QueryParams = setQuery || null;
        if (queryParams) {
          if (queryParams.app) {
            if (!queryParams.tab) {
              queryParams.tab = QueryTab.List;
            }
            coreApp.productService.setQueryParams(queryParams);
          } else {
            window.location.replace('https://gorenjc.si');
          }
        }
      },
    );

    onMounted(loadTranslations);
    return { loaded };
  },
  methods: {
    closeModalSelector(): void {
      this.closeModal = true;

      setTimeout(() => {
        this.closeModal = false;
      }, 550);
    },
    toggleReduce(value: boolean): void {
      this.reducePadding = value;
    },
  },
});
export default App;
</script>

<style lang="scss">
#WebApp {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  .container {
    width: 100%;
    background-color: var(--background-color-app);
    padding: 30px 8px 140px; // footer height +  space (66px)
    height: 100%;

    &.reduce {
      padding-bottom: 30px !important;
    }
  }
}
</style>
