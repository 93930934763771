<template>
  <div v-if="product" class="product-item" :class="{ visible: isProductAvailable }">
    <div class="image-area">
      <div class="image">
        <Image :source="getSource" :alt="product.title" />
      </div>
      <Favorite :product="product" :tab="tab" @toggle-favorite="toggleFavorite" />
    </div>
    <div class="info-area">
      <div class="price">
        <div class="price-discount">€{{ roundUp(getDiscountPrice) }}</div>
        <div v-if="isOldPrice" class="price-old">€{{ roundUp(product.price) }}</div>
      </div>
      <div class="name">{{ truncate(product.title) }}</div>
      <div v-if="product.company" class="company">{{ product.company.display_title }}</div>
    </div>
    <div class="offer-area">
      <div class="valid">
        <Icon class="clock-icon" name="clock" :size="IconSizeType.XSmall" />
        <span class="time-left"> {{ offerTimeLeft }}</span>
      </div>
      <div class="category" :style="categoryBackground">
        <Icon :name="categoryIcon" :size="IconSizeType.XSmall" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { coreApp, translateValues, translate } from '@/core/app';
import { defineComponent, PropType, ref, toRefs, onMounted, getCurrentInstance } from 'vue';
import { Product } from '@/core/data/interfaces/product.interface';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import Favorite from '@/components/Product/Favorite.vue';
import Image from '@/components/Parts/Image.vue';
import Icon from '@/components/Parts/Icon.vue';
import { CategoryType } from '@/core/data/enums/product.enum';
import { QueryTab } from '@/components/data/enums/navigation.enum';

const ProductItem = defineComponent({
  name: 'ProductItem',
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    tab: {
      type: String,
      required: true,
    },
  },
  components: {
    Image,
    Icon,
    Favorite,
  },
  emits: ['remove-product', 'count-favorite'],
  setup: (props) => {
    const instance = getCurrentInstance();
    const { product } = toRefs(props);
    const offerTimeLeft = ref('');
    const isProductAvailable = ref();
    const getValidTo = () => {
      offerTimeLeft.value = '';
      offerTimeLeft.value = getOfferTimeLeft(product.value);
      isProductAvailable.value = coreApp.productService.isProductAvailable(product.value);
      if (!isProductAvailable.value) {
        setTimeout(() => {
          instance?.emit('remove-product', product.value);
        }, 100);
      }
    };

    onMounted(getValidTo);

    const getTimeValidTo = (validTo: string): number => {
      return new Date(validTo).getTime();
    };

    const getOfferTimeLeft = (product: Product): string => {
      const now = new Date().getTime();
      const validTo = getTimeValidTo(product.valid_to);
      return getTimeUnit(validTo - now);
    };

    const getTimeUnit = (distance: number): string => {
      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      if (days && days > 0) {
        return translateValues(
          days > 1 ? 'products_item_valid_to_plural' : 'products_item_valid_to_singular',
          [days.toString(10)],
        );
      } else {
        return translate('products_item_valid_last_day');
      }
    };

    return {
      offerTimeLeft,
      isProductAvailable,
      getValidTo,
    };
  },
  data: () => {
    return {
      IconSizeType,
      nameLength: 42,
    };
  },
  methods: {
    truncate(content: string) {
      if (content && content.length > this.nameLength) {
        return content.substring(0, this.nameLength - 3) + '...';
      }
      return content;
    },
    roundUp: (price: number) => {
      return price.toFixed(2);
    },
    toggleFavorite(favorite: boolean) {
      if (this.tab === QueryTab.Favorites && !favorite) {
        this.$emit('remove-product', this.product);
        this.$emit('count-favorite', false);
      } else {
        this.$emit('count-favorite', favorite);
      }
    },
    validateDiscountPrice(): boolean {
      const discountPrice = this.product.discount_price;
      const originalPrice = this.product.price;

      return !!(discountPrice && discountPrice < originalPrice);
    },
  },
  computed: {
    getSource(): string {
      return this.product.thumb_image_links?.[0] || this.product.image_links?.[0];
    },
    categoryIcon(): string {
      return `${this.product.category?.name || CategoryType.Unknown}`;
    },
    categoryBackground(): { backgroundColor: string } {
      const categoryName = this.product.category?.name.replace('_', '-') || CategoryType.Unknown;
      return {
        backgroundColor: `var(--category-${categoryName.toLowerCase()}-shape)`,
      };
    },
    getDiscountPrice(): number {
      return this.validateDiscountPrice() ? this.product.discount_price : this.product.price;
    },
    isOldPrice(): boolean {
      return this.validateDiscountPrice();
    },
  },
});
export default ProductItem;
</script>

<style lang="scss" scoped>
.product-item {
  width: 100%;
  display: grid;
  grid-template-rows: max-content 1fr auto;
  align-content: start;
  white-space: normal;
  height: 100%;
  opacity: 0;
  transition: opacity var(--duration) var(--cubic-bezier) 0s;

  &.visible {
    opacity: 1;
  }

  & .image-area {
    width: 100%;
    position: relative;
    padding: 42.5% 0;

    &:after {
      content: '';
      background: radial-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    & .image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }

  & .info-area {
    padding: 20px 8px 0;
    position: relative;

    & .price {
      display: inline-flex;
      align-items: flex-end;

      &-discount {
        color: var(--font-color-price);
        font-size: 18px;
        font-weight: 700;
        line-height: 18px;
        margin-right: 8px;
      }

      &-old {
        color: var(--font-color);
        font-size: 13px;
        font-weight: 700;
        line-height: 14px;
        text-decoration: line-through;
      }
    }

    & .name {
      font-size: 13px;
      line-height: 16px;
      margin: 8px 0 4px;
    }

    & .company {
      color: var(--font-color-secondary);
    }
  }

  & .offer-area {
    padding: 12px 8px 0;
    display: inline-flex;
    grid-template-columns: 1fr auto;
    grid-row: 3;

    & .valid {
      background-color: var(--background-color-secondary);
      padding: 0 7px;
      border-radius: 16px;
      height: 28px;
      display: inline-flex;
      justify-self: start;
      align-items: center;
      width: 100%;

      & .time-left {
        color: var(--font-color-primary);
        font-size: 12px;
        margin-left: 6px;
      }
    }

    & .category {
      border-radius: 50%;
      min-width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      justify-self: flex-end;
      margin-left: 8px;
    }
  }
}

@media screen and (max-width: 320px) {
  .product-item {
    & .info-area {
      & .price {
        &-discount {
          font-size: 13px;
        }

        &-old {
          font-size: 12px;
        }
      }
    }

    & .offer-area {
      & .valid {
        & .clock-icon {
          display: none;
        }

        & .time-left {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
