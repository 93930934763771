
import { defineComponent, ref, getCurrentInstance } from 'vue';
import Icon from '@/components/Parts/Icon.vue';
import FilterSelector from '@/components/Filtering/FilterSelector.vue';
import { IconSizeType } from '@/components/data/enums/icon.enum';
import { commonMethods } from '@/components/Mixins/common.mixin';

const Footer = defineComponent({
  components: {
    Icon,
    FilterSelector,
  },
  props: {
    closeModal: {
      type: Boolean,
      required: true,
    },
    hiddenKeyboard: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['trigger-filter', 'trigger-search'],
  mixins: [commonMethods],
  data: () => {
    return {
      IconSizeType,
      isSelectorOpen: false,
    };
  },
  setup: () => {
    const instance = getCurrentInstance();
    const searching = ref(false);
    const searchQuery = ref('');
    const searchInput = ref<HTMLElement | null>(null);
    const html = document.querySelector('html');

    const turnSearch = (value: any) => {
      if (value.inputType === 'deleteContentBackward') {
        searchQuery.value = searchQuery.value.substring(0, searchQuery.value.length - 1);
        if (searchQuery.value.length === 0) {
          instance?.emit('trigger-search', '');
          return;
        }
      } else {
        searchQuery.value += value.data || '';
      }
    };

    const handlePaste = (event: any) => {
      // @ts-ignore
      const paste = (event?.clipboardData || window.clipboardData).getData('text');
      if (paste?.length) {
        searchQuery.value = paste;
      }
      event.preventDefault();
    };

    const submitSearch = (event: any) => {
      if (event.which === 13 || event.code === 'Enter') {
        if (searchQuery.value.length >= 2) {
          instance?.emit('trigger-search', searchQuery.value);
        }
      }
    };

    const addMobileFix = () => {
      window.addEventListener('keypress', submitSearch);
      window.addEventListener('paste', handlePaste);
      html?.classList.add('mobile-fix');

      setTimeout(() => {
        searching.value = true;
      }, 750);
    };

    const removeMobileFix = () => {
      window.removeEventListener('keypress', submitSearch);
      window.removeEventListener('paste', handlePaste);
      html?.classList.remove('mobile-fix');
      searching.value = false;
    };

    return {
      searchInput,
      searchQuery,
      turnSearch,
      addMobileFix,
      removeMobileFix,
      searching,
    };
  },
  methods: {
    clearSearch() {
      this.searchQuery = '';
      this.$emit('trigger-search', '');
    },
    triggerFilter() {
      this.closeSelector();
      this.$emit('trigger-filter');
    },
    showSelector(): void {
      this.isSelectorOpen = true;
    },
    closeSelector(): void {
      this.isSelectorOpen = false;
    },
  },
  watch: {
    hiddenKeyboard(value) {
      if (value && this.searching) {
        const searchInput = (this.$refs.searchInput as HTMLElement) || null;
        if (searchInput) {
          searchInput.blur();
        }
      }
    },
    closeModal(value) {
      if (value) {
        this.closeSelector();
      }
    },
  },
});
export default Footer;
