import pino from 'pino';
import { LoggingInterface, LogLevel } from './logging.interface';
import { PinoLoggerAdapter } from './adapters/pinoLogger';

class Logging implements LoggingInterface {
  private adapter: PinoLoggerAdapter;

  constructor(logger: PinoLoggerAdapter) {
    this.adapter = logger;
  }

  log(level: LogLevel, message: string, ...args: any[]) {
    switch (level) {
      case LogLevel.Debug: {
        this.debug(message, args);
        break;
      }
      case LogLevel.Info: {
        this.info(message, args);
        break;
      }
      case LogLevel.Error: {
        this.error(message, args);
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log(message, args);
        break;
      }
    }
  }

  debug(message: string, ...args: any[]) {
    this.adapter.debug(message, args);
  }

  info(message: string, ...args: any[]) {
    this.adapter.info(message, args);
  }

  error(message: string, ...args: any[]) {
    this.adapter.error(message, args);
  }
}

// build dependencies
const logger = pino({ browser: { asObject: true } });
const adapter = new PinoLoggerAdapter(logger);
const logging = new Logging(adapter);

export default logging;
