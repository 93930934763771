import { HttpClient } from '@/core/network/http/httpClient';
import { Category, Company, DtoProductResponse } from '@/core/data/interfaces/product.interface';
import { HttpResponse } from '@/core/network/http/httpClient.interface';

export class ProductApi {
  private readonly httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async getAllProducts(url: string): Promise<HttpResponse<DtoProductResponse>> {
    return await this.httpClient.get<DtoProductResponse>(url);
  }

  async getCategories(apiEndpoint: string): Promise<HttpResponse<Category[]>> {
    return await this.httpClient.get<Category[]>(`${apiEndpoint}/catalogue/products/categories/`);
  }

  async getCompanies(apiEndpoint: string): Promise<HttpResponse<Company[]>> {
    return await this.httpClient.get<Company[]>(`${apiEndpoint}/catalogue/products/companies/`);
  }

  async addToFavorite(
    apiEndpoint: string,
    productId: string,
    token: string,
  ): Promise<HttpResponse<Company[]>> {
    return await this.httpClient.post<Company[]>(
      `${apiEndpoint}/catalogue/products/${productId}/favorite/`,
      {
        token,
      },
    );
  }

  async removeFromFavorite(
    apiEndpoint: string,
    productId: string,
    token: string,
  ): Promise<HttpResponse<Company[]>> {
    return await this.httpClient.post<Company[]>(
      `${apiEndpoint}/catalogue/products/${productId}/remove-favorite/`,
      {
        token,
      },
    );
  }
}
